/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import "assets/styles/dragula.css";
@import "assets/styles/common.css";


body {
  overflow-y: hidden;
  position  : relative;
  margin    : 0px;
}

html,
body {
  height: 100vh;
  height: 100svh;
}

.fc-widget-content .fc-scroller {
  height: auto !important;
}

@media (min-width: 840px) {
  .custom-mat-panel {
    overflow-y: auto;
    max-width : 60vw !important;
  }
}

.mat-mdc-select-panel {
  max-height: 80vh !important;
  max-height: 80svh !important;
}


/* fullCalendar styles customization */
@media screen and (max-width: 530px) {

  .fc .fc-header-toolbar .fc-toolbar-chunk .btn-group .btn,
  .fc .fc-header-toolbar .fc-toolbar-chunk .btn {
    padding       : 0.25rem 0.5rem !important;
    font-size     : .875rem !important;
    text-transform: lowercase;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
    font-size: medium;

  }
}

.fc .fc-header-toolbar .fc-toolbar-chunk .btn-group .btn.active,
.fc .fc-header-toolbar .fc-toolbar-chunk .btn.active {
  background-color: white !important;
  color           : #0a53be;
  border          : 1px solid;
  border-color    : #0a53be;
}

.fc .fc-header-toolbar .fc-toolbar-chunk .btn-group .btn,
.fc .fc-header-toolbar .fc-toolbar-chunk .btn {
  margin-left     : 0px !important;
  background-color: inherit;
  color           : var(--color-primary);
  border          : none;
}

.fc .fc-header-toolbar .fc-toolbar-chunk .fc-prev-button.btn,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-next-button.btn,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button.btn {
  color           : #fff;
  background-color: var(--color-primary);
  border          : 1px solid;

}

.fc .fc-header-toolbar .fc-toolbar-chunk .fc-prev-button.btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-next-button.btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button.btn:hover {
  color           : var(--color-primary);
  background-color: #fff;
  border          : 1px solid;

}