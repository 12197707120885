@charset "UTF-8";
@font-face {
  font-family: lexendDeca;
  src: url(../../assets/fonts/Lexend_Deca/LexendDeca-VariableFont_wght.ttf)
    format("opentype");
}
/* 
----------------- BREAKPOINTS -----------------
  - mobilne ektrany:            < 320px                 sm
  - tablety, większe telefony:  321px <  < 720px        md
  - desktop:                    721px <  < 1024px       lg
  - duże ekrany:                > 1025px                xl
*/
/* 
----------------- CZCIONKA  - font family -----------------
  Czcionka używana w tym projekcie - Open Sans (darmowa od google).
  Możliwość używania tylko 3 grubości czcionki :
    - font-weight: 300   <- light, delikatna czcionka
    - font-weight: 400   <- regular, do używania w całym projekcie dla standardowego tekstu
    - font-weight: 700   <- bold, headingi, pogrubiony fragment tekstu 
  
  Rozmiary czcionki
    - body text:
      - sm: 1em (16px)
      - md: 1em (16px)
      - lg: 1em (16px)
      - xl: 1em (16px)

    - h1:
      - sm: 2em (32px)
      - md: 2.5em (40px)
      - lg: 3em (48px)
      - xl: 3em (48px)

    - h2:
      - sm: 1.625em (26px)
      - md: 2em (32px)
      - lg: 2.25em (36px)
      - xl: 2.25em (36px)

    - h3:
      - sm: 1.375em (22px)
      - md: 1.5em (24px)
      - lg: 1.75em (28px)
      - xl: 1.75em (28px)

    - h4:
      - sm: 1.125em (18px)
      - md: 1.125em (18px)
      - lg: 1.125em (18px)
      - xl: 1.125em (18px)
*/
:root {
  --color-primary  : #ff7f11; 
  /* --color-primary: #0137fd;
  --color-primary          : #00acc1; */
  --color-secondary: #e3e9ff;
  --color-third: #96adff;
  --color-four: #001c80;
  --color-five: #788bcc;
  --color-danger: #ca0b00;
  --color-warning: #f5bd1f;
  --color-success: #138808;
  --color-font: #191919;
  --color-font-light: #fff;
  --color-button-bg: #fff;
  --color-button-border: #b8b8b8;
  --color-button-background: #a2a2a2;
  --width-button-border: 1px;
}

* {
  box-sizing: border-box;
  font-family: "Lexend-Deca", lexendDeca;
  /* font-weight   : 400; */
  font-size: 1em;
  line-height: 1.375em;
  /* color         : var(--color-font); */
}

h1,
h2,
h3 {
  font-weight: 700;
}

h1 {
  font-size: 3em;
  line-height: 1.05em;
}

h2 {
  font-size: 2.25em;
  line-height: 1.25em;
}

h3 {
  font-size: 1.75em;
  line-height: 1.25em;
}

h4 {
  font-size: 1.125em;
  line-height: 1.222em;
}

/* --------------- xl ---------------  */
@media screen and (min-width: 1025px) {
  .frame {
    padding: 0 30px 30px 30px;
    overflow: auto;
  }

  button .mat-icon {
    /* margin-right: 10px; */
  }

  .map-view {
    width: 100vw !important;
    margin-left: -30px;
  }
}

/* --------------- lg ---------------  */
@media screen and (min-width: 721px) and (max-width: 1024px) {
  h1 {
    font-size: 2.5em;
    line-height: 1.125em;
  }

  h2 {
    font-size: 2em;
    line-height: 1.25em;
  }

  h3 {
    font-size: 1.5em;
  }

  button .mat-icon {
    margin-right: 10px;
  }

  .frame {
    padding: 0 30px 30px 30px;
    overflow: auto;
  }

  .map-view {
    width: 100vw !important;
    margin-left: -30px;
  }

  mat-option span.mat-mdc-option-text {
    height: 6vh;
    height: 6svh;
  }
}

/* --------------- md ---------------  */
@media screen and (min-width: 321px) and (max-width: 720px) {
  * {
    line-height: 1.45em;
    font-size: 0.99em;
  }

  h1 {
    font-size: 2em;
    line-height: 1.25em;
  }

  h2 {
    font-size: 1.625em;
    line-height: 1.153em;
  }

  h3 {
    font-size: 1.375em;
    line-height: 1.136em;
  }

  h4 {
    line-height: 1.111em;
  }

  button .mat-icon {
    margin-right: 5px;
  }

  .frame {
    padding: 0 15px;
    overflow: auto;
  }

  .map-view {
    margin-left: -15px;
  }
}

/* --------------- sm ---------------  */
@media screen and (max-width: 320px) {
  * {
    font-size: 0.97em;
    line-height: 1.45em;
  }

  h1 {
    font-size: 2em;
    line-height: 1.25em;
  }

  h2 {
    font-size: 1.625em;
    line-height: 1.153em;
  }

  h3 {
    font-size: 1.375em;
    line-height: 1.136em;
  }

  h4 {
    line-height: 1.111em;
  }

  button .mat-icon {
    margin-right: 5px;
  }

  .frame {
    padding: 0 15px;
    overflow: auto;
  }

  .map-view {
    margin-left: -15px;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--color-primary);
}

.margin-0 {
  margin: 0;
}

button,
.button {
  background: var(--color-button-bg);
  cursor: pointer;
  padding: 10px 25px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  display: flex;
  outline: none;
  border: var(--width-button-border) solid var(--color-button-border);
  transition: all 0.3s;
  align-items: center;
}

button:disabled,
.button:disabled {
  opacity: 0.6;
  cursor: no-drop;
}

button.btn-primary,
.button.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}

button.btn-primary:hover,
button.btn-primary:active,
button.btn-primary:focus,
.button.btn-primary:hover,
.button.btn-primary:active,
.button.btn-primary:focus {
  background-color: #fff;
  color: var(--color-primary);
}

button [color="warn"],
.button [color="warn"] {
  background-color: #fff;
  border-color: var(--color-danger);
  color: var(--color-danger);
}

button [color="warn"]:hover,
button [color="warn"]:active,
button [color="warn"]:focus,
.button [color="warn"]:hover,
.button [color="warn"]:active,
.button [color="warn"]:focus {
  background-color: var(--color-danger);
  color: #fff;
}

.btn-icon-only {
  border: none;
  padding: 5px;
  margin: 5px;
  color: var(--color-primary);
}

.btn-icon-only:hover,
.btn-icon-only:active,
.btn-icon-only:focus {
  background-color: transparent;
  color: #000;
}

.buttons-container button:hover,
.buttons-container button:active,
.buttons-container button:focus,
.buttons-container .button:hover,
.buttons-container .button:active,
.buttons-container .button:focus {
  background-color: unset;
  color: unset;
}

mat-divider {
  margin-bottom: 10px !important;
}

.margin-typical {
  margin: 10px;
}

.map-view {
  height: 40vh;
  height: 40svh;
  width: 100vw !important;
}

.small-icon-image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

#toast-container > div {
  opacity: 1;
}

.full-width {
  width: 100% !important;
}

.display-flex {
  display: flex !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

/* forms */
.form-buttons {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  background-image: linear-gradient(rgba(255, 0, 0, 0), #fafafa);
  flex-wrap: wrap;
  justify-content: center;
  position: sticky;
  bottom: 0px;
  width: 100%;
}

.form-buttons button {
  margin: 5px;
}

.mat-button.mat-primary,
.mat-mdc-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: var(--color-primary);
}

.mat-input-element {
  caret-color: var(--color-primary);
}

@media screen and (max-width: 320px) {
  .form-buttons button {
    width: 100%;
    justify-content: center;
  }
}

/* calendar layout */
ngx-daterangepicker-material .buttons .btn {
  font-family: inherit;
  font-size: inherit;
  background: var(--color-button-bg);
  cursor: pointer;
  padding: 3px 15px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  display: flex;
  outline: none;
  border: var(--width-button-border) solid var(--color-button-border);
  transition: all 0.3s;
  color: #000;
}

ngx-daterangepicker-material .buttons .btn:hover,
ngx-daterangepicker-material .buttons .btn:active,
ngx-daterangepicker-material .buttons .btn:focus {
  background-color: #000;
  color: #fff;
}

ngx-daterangepicker-material .md-drppicker td.start-date.end-date {
  border-radius: 0px;
}

ngx-daterangepicker-material .md-drppicker td.active,
ngx-daterangepicker-material .md-drppicker td.active:hover {
  background-color: #000;
}

cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;
  justify-content: space-between;
}

cdk-virtual-scroll-viewport .item.ng-star-inserted {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 5px 0;
}

.mat-form-field-prefix {
  align-self: flex-end !important;
}

mat-option span.mat-mdc-option-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

input[type="date"] {
  width: auto;
}

/* overlays */
.cdk-overlay-connected-position-bounding-box {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cdk-overlay-container {
  background-color: rgba(0, 0, 0, 0.6);
}

.cdk-overlay-pane {
  width: 80% !important;;
  transform: translateY(20px) !important;
  display: flex;
  justify-content: center;
  align-items    : center;
  left: unset !important;
  right: unset !important;
  top: 65px !important;
}

.cdk-overlay-pane .mat-mdc-option {
  width: 30%;
  padding: 10px;
  margin: 10px;
  border: 1px solid var(--color-secondary);
}

.cdk-overlay-pane .avaliable-filters .mat-mdc-option {
  width: 90%;
}

.cdk-overlay-pane .mat-mdc-option.building-status,
.cdk-overlay-pane .mat-mdc-option.point-status {
  height: 7em;
  margin: 10px;
}

@media screen and (min-width: 1245px) {
  .cdk-overlay-pane .mat-mdc-option.building-status,
  .cdk-overlay-pane .mat-mdc-option.point-status {
    width: 23%;
  }

  .cdk-overlay-pane .mat-mdc-option.building-status span.mat-mdc-option-text,
  .cdk-overlay-pane .mat-mdc-option.point-status span.mat-mdc-option-text {
    height: unset;
  }
}

@media screen and (min-width: 870px) and (max-width: 1244px) {
  .cdk-overlay-pane .mat-mdc-option.building-status,
  .cdk-overlay-pane .mat-mdc-option.point-status {
    width: 30%;
  }

  .cdk-overlay-pane .mat-mdc-option.building-status span.mat-mdc-option-text,
  .cdk-overlay-pane .mat-mdc-option.point-status span.mat-mdc-option-text {
    height: unset;
  }
}

@media screen and (min-width: 590px) and (max-width: 869px) {
  .cdk-overlay-pane .mat-mdc-option.building-status,
  .cdk-overlay-pane .mat-mdc-option.point-status {
    width: 45%;
  }

  .cdk-overlay-pane .mat-mdc-option.building-status span.mat-mdc-option-text,
  .cdk-overlay-pane .mat-mdc-option.point-status span.mat-mdc-option-text {
    height: unset;
  }
}

@media screen and (max-width: 589px) {
  .cdk-overlay-pane .mat-mdc-option.building-status,
  .cdk-overlay-pane .mat-mdc-option.point-status {
    height: 3.5em;
  }
}

.mat-mdc-select-panel,
.mat-mdc-select-panel .mat-mdc-select-panel {
  display: flex;
  flex-direction: row;
  padding: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 0px !important;
  padding: 10px;
}

.mat-mdc-select-panel .point-status.mat-selected:not(.mat-mdc-option-multiple),
.mat-mdc-select-panel .building-status.mat-selected:not(.mat-mdc-option-multiple),
.mat-mdc-select-panel
  .mat-mdc-select-panel
  .point-status.mat-selected:not(.mat-mdc-option-multiple),
.mat-mdc-select-panel
  .mat-mdc-select-panel
  .building-status.mat-selected:not(.mat-mdc-option-multiple) {
  border: 3px solid var(--color-primary);
  font-weight: bold;
}

.mat-mdc-select-panel .point-status .mat-mdc-option-text,
.mat-mdc-select-panel .building-status .mat-mdc-option-text,
.mat-mdc-select-panel .mat-mdc-select-panel .point-status .mat-mdc-option-text,
.mat-mdc-select-panel .mat-mdc-select-panel .building-status .mat-mdc-option-text {
  flex-direction: column;
  font-size: 15px;
  text-transform: uppercase;
}

@media screen and (max-width: 589px) {
  .mat-mdc-select-panel .point-status .mat-mdc-option-text,
  .mat-mdc-select-panel .building-status .mat-mdc-option-text,
  .mat-mdc-select-panel .mat-mdc-select-panel .point-status .mat-mdc-option-text,
  .mat-mdc-select-panel .mat-mdc-select-panel .building-status .mat-mdc-option-text {
    flex-direction: row;
  }
}

.mat-mdc-select-panel .point-status .mat-mdc-option-text .small-icon-image,
.mat-mdc-select-panel .building-status .mat-mdc-option-text .small-icon-image,
.mat-mdc-select-panel
  .mat-mdc-select-panel
  .point-status
  .mat-mdc-option-text
  .small-icon-image,
.mat-mdc-select-panel
  .mat-mdc-select-panel
  .building-status
  .mat-mdc-option-text
  .small-icon-image {
  margin: 15px;
  height: 55px;
  width: 55px;
}

@media screen and (min-width: 721px) and (max-width: 1024px) {
  .cdk-overlay-pane .mat-mdc-option {
    width: 45%;
    padding: 5px;
    margin: 5px;
  }

  .mat-mdc-select-panel {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 720px) {
  .mat-mdc-select-panel {
    font-size: 15px !important;
    min-width: 100% !important;
  }

  .cdk-overlay-pane .mat-mdc-option {
    width: 100%;
    padding: 5px;
    margin: 5px;
  }
}

.mat-dialog-container {
  border-radius: 0px !important;
  padding: 15px;
}

.ng-star-inserted .mat-dialog-actions {
  margin-bottom: unset;
}

/* ----------------- BREAKPOINTS -----------------
    - mobilne ektrany:            < 320px                 sm
    - tablety, większe telefony:  321px <  < 720px        md
    - desktop:                    721px <  < 1024px       lg
    - duże ekrany:                > 1025px                xl
   */
/* slider */
.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.mat-tab-body-content .modal-calendar .frame.no-offer-overflow div.scheduler {
  flex-direction: column-reverse;
}

.mat-tab-body-content
  .modal-calendar
  .frame.no-offer-overflow
  div.scheduler
  .scheduler-calendar {
  width: 100%;
  margin-right: 0px;
}

.mat-tab-body-content .modal-calendar .frame.no-offer-overflow {
  overflow: hidden;
  height: auto;
}

app-scheduler #openInvestmentButton {
  display: none;
}

/* calendar inputs and calendar adjustments */

.mat-datepicker-toggle button:hover,
.mat-datepicker-toggle button:active,
.mat-datepicker-toggle button:focus,
.mat-datepicker-toggle .button:hover,
.mat-datepicker-toggle .button:active,
.mat-datepicker-toggle .button:focus {
  background-color: unset;
  color: unset;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: unset;
  padding: 0 !important;
  border-radius: 0px;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 5px;
}

.mat-menu-panel {
  border-radius: 0px;
}

.icon-wrapper {
  display: inline-block;
  background-color: white;
  padding: 10px; 
  border-radius: 8px; 
  box-shadow: 0 0 8px rgba(0,0,0,0.1); 
}